.container {
  max-width: 1140px;
  margin: 50px auto;
}

.feed-header {
  text-align: center;
  margin-top: 90px;
  font-size: 50px;
}

.feed__search{
  width: 60%;
  margin: 123px auto;
  min-width: 400px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 20px;
  border: 1px solid var(--color-border);
  border-radius: 100px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-top: 60px;
}

.search__input{
  color: var(--color-headings);
  font-size: 1.5rem;
  outline: 0;
  padding: 2rem 2rem;
  border: 0;
  cursor: pointer;
  width: 100%;
}


.search-btn {
  position: absolute;
border-radius: 40px;
border: 0;
cursor: pointer;
font-size: 1.8rem;
font-weight: 600;
/* margin: 1rem 0; */
outline: 0;
padding: 1.5rem 2rem;
text-align: center;
text-transform: uppercase;
white-space: nowrap;
right: 0px;
}


.Search__filter{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
}

.Search__filter--option{
  border-radius: 40px;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 2rem 2.5rem;
  margin-right: 20px;
  font-size: 1.5rem;
  font-weight: 500;
  background-color: #efefef;
  border-right: 10px solid transparent !important;
}

.search-result{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black; 
 }


.search__filter{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.profile__feedbar--option{
  margin-right: 10px;
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-body-darker);
  padding: 3px 30px;
  cursor: pointer;
}

.profile__feedbar--option:hover{
  background-color: #e3e3e6;
  border-radius: 10px;
  border: 0px;
}

/* Search Results */
.search-results {
  height: 100%;
  margin-top: 30px;
  width: 60%;
}


.search-result-container {
  display: flex;
  justify-content: flex-start;
  border-top: 1px solid #d9d9d9;
  padding-top: 15px;
  padding-bottom: 15px;
}

.search-result-contents {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  justify-content: space-between;
}

.search-result-contents-top {
  display: flex;
  flex-direction: column;
}

.search-img {
  border-radius: 10px;
  width: 300px;
  height: 175px;
}

.search-result-date {
  color: var(--color-body);
}

.search-result-title {
  color: var(--color-headings);
  margin-top: 10px;
  font-size: larger;
}

.search-result-location {
  color: var(--color-body-darker);
  margin-top: 5px;
}

.search-result-attendees {
  color: var(--color-body-darker);
}

.search-result-contents-bottom {
  margin-bottom: 10px;
}

.search-see-more-info {
  color: var(--color-blue-dark);
}

.search-see-more-info:hover {
  text-decoration: underline;
  cursor: pointer;
}

.no-results-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.no-results-img {
  height: 150px;
  width: 150px;
  margin-bottom: 10px;
}

.no-results-text {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-results-text-main {
  font-size: 30px;
}

.link-to-home {
  margin-top: 10px;
  text-decoration: underline;
  color: #056c91;
}



