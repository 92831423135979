.activity__img{
    width: 100%;
    height: 250px;   
    /* object-fit: contain; */
    border-radius: 10px;
    margin-top: 10px;
    align-self: center;
}

.show__activity--option{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.5rem;
    margin-top: 5px;
}

.option--left{
    flex: 0.3;
    font-size: 1.5rem;
}

.option-right{
    flex: 1;
    font-size: 1.4rem;
}

.option-right > a{
    text-decoration: none;
}

.activity-show-title{
    font-size: 2rem;
}

.request--join{
    padding: 3px 15px;
    font-weight: 900;
    color: #0d4175;
    border-radius: 5px;
    border: 1px solid #0d4175;
    cursor: pointer;
}

.request--join:hover{
    background: #0d4175;
    color: white; 
    /* border: 0;  */
}

.option--item{
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    color: #0d4175;
}

.pending-request-name{
    font-size: 1.5rem;
    color: #0d4175;
}