.nav {
    background: #0d4175;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0.8rem;
    padding-left: 5rem;
    padding-right: 1rem;
    align-items: flex-end;
    /* box-shadow: 0 0 300px rgb(207, 207, 207); */
/*     box-shadow: 0 0 5px #0d4175; */
    position: fixed;
    z-index: 999999;
    width: 100%;
    top:0;
}

.nav_left{
    display: flex;
    flex-direction: row;    
    
}

.nav__logo{
    /* border-bottom: 2px solid rgb(207, 207, 207); */
    cursor: pointer;
}

.nav__avatar{
    border-radius: 50%;
    width: 45px;
    height: 40px;
    border: 6px solid white;
    object-fit: cover;
    /* margin-right: 20px;     */
}


.nav__right{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10%;
    cursor: pointer;
}

.nav__menu--more{
    position: relative;
}

.nav__menu--more:hover > .nav__menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;  
        
}

.nav__right--option{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 30px;
    background: rgb(236, 235, 235);
    margin-right: 20px;
}

.nav__right--option:hover{
    background: rgb(235, 230, 230);
}

.nav__menu{
    position: absolute;
    display: none;
    top: 35px;
    right: -10px;
    background-color: white;
    min-width: 200px;
    border-radius: 5px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 5px 5px;
    z-index: 1;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--color-body-darker);
    

}

.nav__menu--option{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 10px 5px;
    border-radius: 0px;
    width: 100%;
}

.nav__menu--option:first-child{
    /* border-bottom: 1px solid var(--color-body); */
}
/* 
.nav__menu > div{
    width: 100%;
    margin-top: 5px;
    border-radius: 5px;
    padding: 5px 5px;
 
} */

.nav__menu--option:hover{
   background: rgb(246, 245, 245);
}

.nav__menu-img{
    margin-right: 10px;
}


.nav__userinfo{
    font-size: 1.5rem;
    color: var(--color-body-darker);
}


.nav__search{
    display: flex;
    align-items: center;  
    margin-top: 10px;
    margin-left: 10px;
   
    /* width: 200px; */
}

.nav__search .input {
    border: 0;
    flex-grow: 1;
    font-size: 1.5rem;
    width: 200px;
    padding: 1rem 1.5rem;
    border-radius: 10px;
  }


/* .message__icon{
    font-size: 1.5rem;
    color: var(--color-primary);
} */

.nav__slogan{
    text-align: right;
    margin-top: 3px;
    font-size: 1rem;
    color: var(--color-headings);
}

/* .nav__right{
.nav__right{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
} */

.nav__list {
    display: flex;
    /* display: none; */
    width: 100%;
    margin: 0;
}
  
.nav__item {
    padding: 0.5rem 0.5rem;
    /* border-bottom: 1px solid #222; */
    cursor: pointer;
    font-size: 1rem;
    /* color: var(--color-body-darker); */
    color: white;
    margin-right: 20px;
    font-weight: 500;
}

.bio__item {
    padding: 0.5rem 0.5rem;
    /* border-bottom: 1px solid #222; */
    cursor: pointer;
    color: var(--color-body-darker);
}
  
.nav__item > a {
    color: var(--color-body-darker);
    transition: color 0.3s;
}
  
.nav__item > a:hover {
    color: var(--color-body);
}


.nav__user{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1px;
    /* padding: 5px 10px;
    background-color: #f1f2f5;
    border-radius: 50px; */
    
}

.nav__user--img{
    /* margin-right: 10px; */
    font-size: 1rem;
    color: gray;
}

.nav__user--username{
    font-size: 1.2rem;
    font-weight: 600;
    color: black;

}

.navbar__search{
  width: 200;
  margin-left: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 20px;
  /* border: 1px solid var(--color-border); */

  border-radius: 30px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}


.navbar__search__input{
    color: white;
    font-size: 1.5rem;
    outline: 0;
    padding-bottom: 0.5rem;
    border: 0;
    cursor: pointer;
    background: #0d4175;
    width: 100px;
    border-bottom: 1px solid var(--color-border);
  }

  .navbar__search-btn{
    background: #0d4175;
    outline: 0;
    border: 0;
    cursor: pointer;
  }
  

@media screen and (min-width: 768px) {
  
    .nav__list {
      width: auto;
      font-size: 1.6rem;
      max-height: 100%;
      opacity: 1;
    }
  
    .nav__item {
        font-size: 1.5rem;
        border: 0;
    }

    
}