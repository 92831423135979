
.footer{
    background: var(--color-callout);
    padding-top: 2.5rem;
    padding-bottom: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;  
    justify-content: center;  
    height: 20rem;
}

.team__info{
    display: flex;
    flex-direction: column;
    margin-right: 3rem; 
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    font-size: 1.5rem;
    cursor: pointer;
   
}
    

.team__info--img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50px;  
    margin-right: 2rem;
 
}

.team__info--detail{
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    color: whitesmoke;
}

.info--name{
    font-size: 1.6rem;
    font-weight: 800;
    color: whitesmoke;
}

.team-info-label {
    margin-left: 10px;
}

.team-info-angellist{
   padding: 2px 2px;
   width: 22px;
   height: 22px;
   background-color: #fff;
   border-radius: 50%;
}

@media screen and (min-width: 950px) {
    .footer{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .team__info{
        display: flex;
        flex-direction: row;
    }
}