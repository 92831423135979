.modal-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(245, 245, 245, 0.7);
    z-index: 999;
  }
  
  
  .modal-background > .close-x {
    position: absolute;
    background-color: #eff2f5;
    cursor: pointer;
    top: 5px;
    right: 17px;
    padding: 0px 5px;
    font-size: 20px;
    font-weight: 900;
    border-radius: 999px;
  }
  
  
  .modal-child {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  } 
  
  
  .close-x {
    position: absolute;
    /* background-color: #eff2f5; */
    cursor: pointer;
    top: 5px;
    right: 17px;
    /* width: 25px;
    height: 25px; */
    border-radius: 30px;
    font-size: 20px;
    font-weight: 900;
    border-radius: 999px;
  }
  
  .close-x:hover{
    /* background-color: lightgray; */
    font-size: 25px;
  }



.from__submit__delete{
    display: flex;
    justify-content: space-around;
    padding: 10px 10px;
}

