/* story*/
.acivity_feed{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
 
}

.activity-feed-bottom {
    display: flex;
    justify-content: center;
    width: 100%;
}

.acivity_feed__heaher{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    width: 90%;
    margin-top: 30px;
}

.acivity_feed__heaher > h2{
    font-size: 2.5rem;
    padding-left: 12%;
}

.acivity_feed__heaher > p{
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--color-accent);
    cursor: pointer;
}

.acivity_feed__heaher > p:hover{
    transform: scale(1.2);
}

.activity{
    
    position: relative;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
   
    height: 250px;
    
    box-shadow: 0px 5px 17px -7px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    margin-right: 20px;
    margin-left: 20px;
    transition: transform 100ms ease-in;
    cursor: pointer;
    margin-top: 30px;

    width: calc(90% - 10px);;
}

.activity:hover{
    transform: scale(1.1);
}

.activity__avatar{
    margin: 10px;
    border: 5px solid #2e81f4;

}


.activity__avatar--white{
    border: 5px solid white;

}

.activity{
    border: 1.5px solid white;
    background-color: white;
    box-shadow: 0 0 7px rgb(207, 207, 207), 0 0 10px rgb(238, 238, 238); 
}

.activity > h4{
    position: absolute;
    bottom: 5px;
    left: 15px;
    color: var(--color-body-darker);
    font-weight: 700;
}

.activityreel{    
    display: flex;    
    width: 100%;
    flex-wrap: wrap;
    /* justify-content: center; */
    align-items: center;
    padding-left: 12%;
    /* padding: 15px 20px; */
}

.activity__item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activity_details{
    margin-top: 15px;
    padding-left: 25px;
    align-self:flex-start;
    width: 100%;
}

.activity_details--title{
    font-size: 1.7rem;
    font-weight: 600;
    margin-bottom: 3px;
    color: var(--color-body-dark);   
}

.activity_details--date{
    font-size: 1.3rem;
    margin-bottom: 3px;
    color: var(--color-body);
}

.activity_details--location{
    font-size: 1.3rem;
    margin-bottom: 3px;
    color: var(--color-body);
}

.activity_details--option{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;

}

.activity_details--people{
    display: flex;
    justify-content: center;
    align-items:flex-end;
    font-size: 1.5rem;
}

.activity_details--join{
    padding: 3px 15px;
    font-weight: 900;
    color: #0d4175;
    border-radius: 5px;
    /* border: 1px solid #0d4175; */
    cursor: pointer;
}

.activity_details--join:hover{
    background: #0d4175;
    color: white; 
    /* border: 0;  */
}

.pending {
    cursor:default;
}

.pending:hover {
    background: transparent;
    color: #0d4175;
}

.link-to-search {
    padding-right: 11%;
    color: var(--color-headings)
}

.link-to-search:hover {
    cursor: pointer;
    text-decoration: underline;
}


@media screen and (max-width: 767px){
    .activity__item{ 
        width: calc(50%);
    }
}

@media screen and (min-width: 768px) {
    .activity__item{ 
        width: calc(20.3% - 5px);
    }

    .activityreel{   
     
        width: 100%;
   
    }
}
