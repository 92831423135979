.profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
}


.profile__top{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    width: 100%;  
    margin: 0 auto;  
    position: relative; 
    height: 440px; 
}

.profile__coverpotp{
    top: 0;
    position: absolute;
    /* background: linear-gradient(#f1f2f5 , #e3e3e6 80%); */
    background: #0d4175;
    width: 100%;
    min-height: 280px; 

}

.profile__header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 100%;
    margin: 15px auto;
}

.profile__userinfo--heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile__userinfo--heading > h1{
    font-size: 1.8 rem;
    font-weight: 700;
    text-align: center;
}

.profile__userinfo--heading > h2{
    font-size: 1.5rem;
    text-align: center;
}

.profile__userinfo--heading > p{
    font-size: 1.3rem;
    
}

.profile__useravatar{
    /* margin: 2px 2px; */
    border-radius: 50%;
    width: 208px;
    height: 208px;
    margin-right: 10px;
    border: 6px solid white;
    object-fit: cover;  
}

.profile__option{
    font-size: medium;
    font-weight: 500;
    color: rgb(90, 88, 88);
    margin-right: 20px;
    padding: 15px 15px;
}

.profile__img{
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 130px;
    justify-content: center;
    align-items: center; 
    margin-top: 20px; 
}

.profile__img--avatar{
    position: relative;
}

.profile__img--edit{
    position: absolute;
    display: flex;
    top: 140px;
    right: 0px;
    z-index: 9;
    cursor: pointer;
}

.profile__img--edie:hover{
    background: #e3e3e6;
}

.profile__feed{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.profile__feedbar{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.profile__feedbar--option{
    margin-right: 10px;
    font-size: 2rem;
    font-weight: 500;
    color: var(--color-body-darker);
    padding: 3px 30px;
    cursor: pointer;
}

.profile__feedbar--option:hover{
    background-color: #e3e3e6;
    border-radius: 10px;
    border: 0px;
}

.active{
    border-bottom: 3px solid var(--color-primary);
}

.active:hover {
    border-bottom: 3px solid transparent;
}

.profile__about{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}

.profile__about--slogan {
    padding-top: 40px;
    font-size: 1.5rem;
    /* font-weight: 700; */
    text-align: center;
    line-height: 0;
}

.profile__about--slogan > p {
    color: white;
}   

.profile__about--slogan-blod{
    font-weight: 800;
}


.profile__about--intro, .profile__about--next{
    /* max-width: 350px; */
    font-size: 1.5rem;
    color: var(--color-body-darker);

    box-shadow: 0 0 7px rgb(207, 207, 207), 0 0 10px rgb(238, 238, 238); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 15px;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px 10px;
    margin-bottom: 20px;
}

.profile__about--next {
    cursor: pointer;
}

.profile__about--next > div{
    padding-left: 10px;
}

.next-activity-header {
    margin-bottom: 10px;
}

.profile__about--left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    /* padding: 10px 10px; */
    border-radius: 15px;
    margin-bottom: 25px;
}



.profile__about--right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profile__about--edit{
    align-self: center;
}

.profile__events{
  
    /* background: linear-gradient(#f1f2f5 , #e3e3e6 80%); */
    width: 100%;
    min-height: 205px; 
    display: flex;
    justify-content: center;
}

.profile-name {
    margin-top: 0px;
}

.profile-pronouns {
    margin-top: 0px;
}

.profile-age {
    margin-top: 0px;
}



@media screen and (min-width: 768px) {
    .profile__header{
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .profile__userinfo{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 0;
    }



    .profile__userinfo--heading > h1{
        font-size: 2em;
     
    }

    
    .profile__userinfo--heading > p{
        font-size: 1.3rem;
        
    }

    .profile__navbar{
        display: flex;
        justify-content: center;
        align-items: center;           
        margin-top: 10px;
        
    }

    .profile__about{
        margin-top: 25px;   
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
   
    }

    .profile__about--left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-right: 50px;
        width: 300px; 
        height: 100%;      
    }
    
    .profile__about--right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 650px;
        height: 100%;
        
    }


    .profile__about--slogan {
        margin-top: 30px;
        /* font-size: 2.5rem; */
        /* font-weight: 700; */
        color: black;
        text-align: center;
        line-height: 0;
    }

    .profile__about--slogan {
        font-size: 2.5rem;
    }

  


}




