.messages-modal-container {
    position: fixed;
    right: 10px;
    bottom:0;
}

.conversations-container {
    width: 350px;
    height: calc(100vh - 300px);
    box-shadow: 0px 0px 0px 1px #00000014,
    0px 4px 4px #0000004d;
    border-radius: 8px;
    position: fixed;
    bottom: 0;
    right: 0;
    overflow: auto;
    background-color: white;
}

.conversations-container::-webkit-scrollbar {
    display: none;
}

.conversations-container-collapsed {
    width: 350px;
    height: 70px;
    box-shadow: 0px 0px 0px 1px #00000014,
    0px 4px 4px #0000004d;
    border-radius: 8px;
    position: fixed;
    bottom: 0;
    right: 0;
    overflow: auto;
}

.conversations-container-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}

.conversations-container-nav {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: black;
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    cursor: pointer;
    position: fixed;
    background-color: white;
}

.conversations-container-nav-left {
    display: flex;
    align-items: center;
}

.conversations-container-nav-right {
    display: flex;
    align-items: center;
}

.conversations-container-nav-header {
    margin-right: 10px;
}

.conversations-list {
    padding-left: 10px;
    padding-right: 10px;
    overflow: auto;
    margin-top: 70px;
}

.conversations-list::-webkit-scrollbar {
    display: none;
}

.chat-group-img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
}

.chat-group-list-item {
    display: flex;
    align-items: center;
    padding-top: 20px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    cursor: pointer;
}

.chat-group-list-item-right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.chat-group-list-item-name {
    color: black;
    font-size: 18px;
}

.chat-group-list-item-message {
    color: #00000099;
    font-size: 14px;
}

.hidden {
    display: none;
}

.chat-container {
    position: fixed;
    bottom: 90px;
    right: 390px;
    height: 310px;
    width: 360px;
    box-shadow: 0px 0px 0px 1px #00000014,
    0px 0px 4px #0000004d;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: auto;
    background-color: white;
}

.chat-container::-webkit-scrollbar {
    display: none;
}

.chat-header {
    height: 50px;
    border-bottom: 1px solid lightgrey;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    position: fixed;
    background-color: white;
    width: 360px;
}

.chat-header-left {
    display: flex;
    align-items: center;
}

.close-button:hover {
    cursor: pointer;
}

.messages-container {
    overflow: auto;
    margin-top: 50px;
}

.message-composer {
    position: fixed;
    bottom: 0;
    height: 90px;
    width: 360px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid lightgrey;
    box-shadow: 0px 0px 0px 1px #00000014,
    0px 4px 4px #0000004d;
    background-color: white;
}

.message-input {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    background: transparent;
}

.message-composer > input:focus-visible {
    box-shadow: none;
    outline: none;
}

.send-icon {
    margin-right: 15px;
    cursor: pointer;
}

.message-img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.message-list-item {
    display: flex;
    align-items: center;
    padding-top: 20px;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 10px;
    padding-left: 10px;
}

.message-list-item-right {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.message-list-item-name {
    color: black;
    font-size: 18px;
}

.message-list-item-text {
    color: #00000099;
    font-size: 14px;
}

.hide-chat-group-button {
    margin-left: 10px;
}

.hide-chat-group-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}