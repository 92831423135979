/* Features */

.feature__block{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 0 auto; */
    width: 75%;
    padding-left: 12%;
    padding-right: 17%;
    
}

.feature {
    gap: 4rem 2rem;
    margin: 8rem 12rem;
    margin-left: 0px;
    margin-bottom: 60px;
}

.feature:first-of-type {
    margin-top: 6rem;
}

.feature__content{
    padding: 0 20px;
    flex: 1;
    margin-right: 6%;
}

.feature__content-2 {
    margin-left: 4%;
}

.feature__heading {
    margin: 1rem 0;
    font-size: 60px;
    line-height: normal;
}

.feature__image{
    object-fit: contain;
    border-radius: 15px;
}

@media screen and (min-width: 1300px) {

    .feature:nth-of-type(even) .feature__content {
        order: 2;
      }

    .feature__block{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;    
    }
    .feature__image{
        width: 100%;
        flex: 1;
    }
   
}
  