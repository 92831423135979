:root {
    --color-nav: #0d4175;    
    --color-callout: #0d4175;
    --color-primary: #2584ff;
    --color-secondary: #00d9ff;
    --color-accent: #ff3400;
    --color-blue-dark: #2596be;
    --color-headings-bg: #243b52;
    --color-headings: #1b0760;
    --color-body: #918ca4;
    --color-body-darker: #5c5577;
    --color-sliver: #efeeee;
    --color-border: #ccc;
    --border-radius: 30px;
  }
  
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  
  li {
    list-style: none;
  }
    
  html {
    font-size: 62.5%;
  }
  
  img {
    width: 100%;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    font-size: 2rem;
    line-height: 1.3;
    color: var(--color-body-darker);
    /* background-image: linear-gradient(to right bottom, #ffffff, #f2f2f2, #e5e5e5, #d9d9d9, #cccccc); */
  }
  
  
  h1,
  h2,
  h3 {
    color: var(--color-headings);
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  
  h1 {
    font-size: 6rem;
  }
  
  h2 {
    font-size: 4rem;
  }
  
  h3 {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.5;
  }
  
  p {
    margin-top: 0;
    margin-bottom: 5px;
  }

  a {
    text-decoration: none;
    cursor: pointer;
    color: var(--color-body-dark)
  }
  
@media screen and (min-width: 1024px) {
    body {
      font-size: 1.8rem;
    }
  
    h1 {
      font-size: 8rem;
    }
  
    h2 {
      font-size: 4rem;
    }
  
    h3 {
      font-size: 2.4rem;
    }
  }
  

  
  
  @media screen and (min-width: 450px) {
 
  }


  /* Lists */
.list {
  list-style: none;
  padding-left: 0;
}

.list__item {
  display: inline-block;
  margin-right: 2rem;
}

/* Inputs */
.input {
  border-radius: var(--border-radius);
  border: 1px solid var(--color-border);
  color: var(--color-headings);
  font-size: 1.5rem;
  outline: 0;
  padding: 1.5rem 3.5rem;
}

::placeholder {
  color: #cdcbd7;
}

.autocomplete-dropdown-container{
  position: absolute;
  z-index: 999; 
}

.input-sugestion{
  width: 437px;
  flex: 1;
  background: white;
  border-bottom: 1px black dotted;
  font-size: 1.5rem;
  padding: 10px 5px;
}



.input-group {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius);
  display: flex;
  margin-bottom: 10px;
}

.input-group .input {
  border: 0;
  flex-grow: 1;
  padding: 1rem 1.5rem;
}

.input-label {
  color: var(--color-headings);
  font-size: 15px;
  margin-left: 10px;
  font-weight: 700;
}

/* Buttons */
.btn {
  border-radius: 40px;
  border: 0;
  cursor: pointer;
  font-size: 1.8rem;
  font-weight: 600;
  /* margin: 1rem 0; */
  outline: 0;
  padding: 2rem 3rem;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.btn .icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.btn--primary {
  background: var(--color-primary);
  color: #fff;
}

.btn--primary:hover {
  background: #3a8ffd;
}

.btn--secondary {
  background: var(--color-secondary);
  color: #fff;
}

.btn--secondary:hover {
  background: #05cdf0;
}

.btn--blue-dark{
  background-color: var(--color-callout);
  color: #fff;
}

.btn--blue-dark:hover {
  background: #1767b6;
}

.btn--grey {
  background: var(--color-border);
  color: #fff;
}


.btn--grey:hover {
  background: #cdcbd7;
}

.btn--accent {
  background: var(--color-accent);
  color: #fff;
}

.btn--accent:hover {
  background: #ec3000;
}

.btn--small{
  padding: 10px 10px;
  font-size: 1rem;
  font-weight: 700;
}

.btn--medium {
  padding: 10px 10px;
  font-size: 1.3rem;
  font-weight: 700;
}


/* Blocks */
.block {
  --padding-vertical: 1rem;
  padding: var(--padding-vertical) 2rem;
}

.block__header {
  text-align: center;
  padding-top: 1rem;
  margin-bottom: 2rem;
}

.block__heading {
  margin-top: 0;
}

.block--dark {
  background: var(--color-backgroud);
  color: #7b858b;
}

.block--dark h1,
.block--dark h2,
.block--dark h3 {
  color: #fff;
}

.block--skewed-right {
  padding-bottom: calc(var(--padding-vertical) + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 95%);
}

.block--skewed-left {
  padding-bottom: calc(var(--padding-vertical) + 4rem);
  clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 0% 100%);
}

.container {
  max-width: 1140px;
  margin: 0 auto;
}
