.activity-show-container {
    border: 1.5px solid white;
    background-color: white;
    box-shadow: 0 0 7px rgb(207, 207, 207),
    0 0 10px rgb(238, 238, 238);
    border-radius: 10px;
    padding: 2px;
    top: 15px;
    display: flex;
    flex-direction: column;
    padding: 10px 30px;
    max-width: 500px;
}

.activity-show-header {
    width: 100%;
    max-width: 500px;
    display: flex;
    justify-content: center;
}

.activity-show-title {
    margin-top: 0 !important;
}

.activity-show-host:hover {
    text-decoration: underline;
}

.approved-users-list {
    padding: 0px;
    margin-top: 5px;
    display: flex;
}

.approved-user-label {
    position: absolute;
    font-size: 10px;
    display: none;
}

.approved-user-item {
    display: flex;
    justify-content: center;
    padding-right: 10px;
}

.approved-user-img {
    margin-top: 10px;
}

.approved-user-item:hover > .approved-user-label {
    display: block;
}

.pending-request-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.pending-request-item-left {
    display: flex;
    align-items: center;
}

.btn--pending {
    margin-left: 10px;
}

.pending-request-name {
    font-size: 18px;
}

.activity-show-container > div > strong {
    color: var(--color-headings)
}

.activity-show-container > div > span {
    color: var(--color-headings);
}

.pending-message {
    color: var(--color-body-darker) !important;
}

.btn--request {
    margin-top: 20px;
    margin-left: 0px;
}