.form__box { 
     border: 1.5px solid white;
     background-color: white;
     box-shadow: 0 0 7px rgb(207, 207, 207), 0 0 10px rgb(238, 238, 238);   
     border-radius: 10px;
     padding: 2px;
     top: 15px; 
     display: flex;
     flex-direction: column;
     padding: 10px 30px;
}


.form__header {

    text-align: center;
    width: 100%;
    border-bottom: 1px solid lightgray;
    margin-bottom: 20px;
}


.form__header > h1{
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
}

.form__header > h2{
    font-size: 1.5rem;
    font-weight: 700;
}

.form__header > p{
    margin: 5px;
    font-size: medium;
    text-align: left;
}

.form__avatar{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 10px;
}

.form__avatar > p{
    margin-left: 3px;
    font-size: medium;
    font-weight: 600;
}

.form__submit{
    display: flex;
    justify-content: center;
}

.form__options{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.form__input{
   border-radius: 10px;
}

.form__group{
    display: flex;
    flex-direction: column;
}

.demo-button {
    margin-left: 20px;
    cursor: pointer;
}

.demo-button:hover {
    background-color: #dfdede;
}

.form__group > .input-group >.input{
    width: 100%;
          
 }



@media screen and (min-width: 600px) {
    .form__box {
      
        width: 500px;
    }

    .form__group{
        display: flex;
        flex-direction: row;
             
    }

    .form__group > .input-group{
        margin-right: 3px;
             
    }

    
}


.longin__errors{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    
}

.longin__errors > li{
    color: var(--color-accent);
    margin: 0 0;
    line-height: 0.5;
}

.signup__form{
    margin-bottom: 20px;
    margin-right: 30px;
}

.form__box > .input-group{
    margin-bottom: 25px;
}

.signup__form p {
    position: absolute;
    color: red;
    font-size: 1.5rem;
}


.fnameError {
  
    top: 340px;
    left: 50px;
}

.lnameError {
    top: 340px;
    left: 255px;
}

.emailError {
    top: 410px;
    left:  50px;
}

.passwordError {
    top: 470px;
    left:  50px;
}

.passwor2dError{
    top: 535px;
    left:  50px;
}

.ageError{
    top: 600px;
    left:  50px;
}

.avatar__edit{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}

/* Avatar */
.profile__file{

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
       
    padding: 10px 0px;
    margin: 10px 10px;
    margin-bottom: 60px;
    min-height: 120px;
}

.signup__avatar{
    position: absolute;
    top: 140px;
    left: 230px;
    z-index: 99;

}

.edit__avatar{
    position: absolute;
    top: -25px;
    left: 360px;
    z-index: 99;
    cursor: pointer;
}

.delete__avatar{
    position: absolute;
    top: -25px;
    left: 400px;
    z-index: 99;
    cursor: pointer;
}

.profile__file--edit{
    position:absolute;
    z-index: 999999;
    height: 150px;
    width: 100%;

}

.profile__img--circle{
    position:absolute;
    top: 5px;
    left: 100px;
    width: 160px;
    height: 140%;
    border-radius: 100px; 
    border: 1px solid gray;
    object-fit: cover;
}

.profile__avatar{
    margin-bottom: 50px;
} 

.custom-file-input {
    position: relative;
    color: transparent;
    cursor: pointer;
}
.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}
   